import request from './request'

const auth = '/auth' // 活体认证授权解决跨域 重新路径
const api = '/api' // 活体认证解决跨域 重新路径

const url = {
  oauthToken: '/oauth/token', // 授权
  getFaceQRCode: '/api/face/getFaceQRCode', // 获取活体认证二维码/订单ID
  getTpFaceVerifyResult: '/api/face/getTpFaceVerifyResult' // 获取活体认证二维码/订单ID
}

const requestSXAPI = {
  oauthToken: params => {
    return request.get(url.oauthToken, params, auth)
  },
  getFaceQRCode: params => {
    return request.postJson(url.getFaceQRCode, params, api)
  },
  getTpFaceVerifyResult: params => {
    return request.get(url.getTpFaceVerifyResult, params, api)
  }
}

export default requestSXAPI
