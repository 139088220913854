import axios from 'axios'

// 请求超时时间，300s
const requestTimeOut = 300 * 1000

// 提示信息显示时长
// const messageDuration = 5 * 1000

// 系统全局请求对象
const service = axios.create({
  timeout: requestTimeOut,
  responseType: 'json'
})

// service.interceptors.request.use(
//   config => {
//     const _config = config
//     const token = sessionStorage.getItem('TOKEN')
//     _config.headers.token = token
//     return _config
//   },
//   error => {
//     console.log(error)
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use((config) => {
  if (config.status === 200) {
    return config.data
  } else {
    return Promise.reject(config)
  }
}, (error) => {
  return Promise.reject(error)
})

const request = {
  post (url, params, pathRewrite) {
    return service.post(url, params, {
      baseURL: pathRewrite,
      transformRequest: [(params) => {
        return tansParams(params)
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  get (url, params, pathRewrite) {
    return service.get(
      url,
      {
        baseURL: pathRewrite,
        params
      }
    )
  },
  postJson (url, params, pathRewrite) {
    const headers = {
      'Content-Type': 'application/json'
    }
    if (sessionStorage.getItem('TOKEN_TYPE') && sessionStorage.getItem('ACCESS_TOKEN')) {
      headers.Authorization = `${sessionStorage.getItem('TOKEN_TYPE')} ${sessionStorage.getItem('ACCESS_TOKEN')}`
    }
    return service.post(url, params, {
      baseURL: pathRewrite,
      headers: headers
    })
  }
}

const tansParams = params => {
  let result = ''
  Object.keys(params).forEach((key) => {
    if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
      result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
    }
  })
  return result
}

export default request
